<template>
  <div class="bg-f">
    <!--公共头部-->
    <Header></Header>
    <!-- 用户中心头部 -->
    <user-header />
    <!-- 内容 -->
    <div class="main-content">
      <div class="container">
        <!-- <div class="my-order01">
          <span>首页</span>
        </div> -->
        <div class="accout-content clearfix"  style="display:flex">
          <!-- 左边 -->
          <user-left mark="event" :userId="userId" />
          <!-- 右边 -->
          <a-card :bordered="false" class="card-right" style="width:100%">
            <a-table ref="table" :row-key="record=>{return record.purchaseInfoId}" :columns="columns" :data-source="list" :pagination="pagination" @change="onChange1">
                <span slot="action" slot-scope="text, record">
                  <router-link :to="{name: 'EventDetail', params: {id: record.purchaseInfoId, userId: userId } }">{{ $t('user.detail') }}</router-link>
                </span>
                <span slot="action2" slot-scope="text, record" @click="record.status==0 ? checkstatus(record.purchaseInfoId, 1): ''">
                  {{record.statusText}}
                </span>
            </a-table>
          </a-card>
        </div>
      </div>
    </div>
     <!-- 公共尾部 -->
    <Footer></Footer>
  </div>
</template>

<style>
  .ant-card-body{
    text-align: left;
  }
  .ant-table-body{
    margin-top: 10px;
  }
</style>

<script>
import UserHeader from './components/Header'
import UserLeft from './components/Left'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Editor from '@/components/Editor/TinyMceEditor'

export default {
  inject: ['reload'], // 注入依赖
  name: 'UsersEvent',
  components: {
    UserHeader,
    Footer,
    UserLeft,
    Editor,
    Header
  },
  data () {
    return {
      userId: this.$route.params.userId,
      list: [],
      page: 1,
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        defaultCurrent: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 0, // 总数，必须先有
        pageSizeOptions: ['10', '20', '30', '50', '100'],
        showTotal: (total, range) => {
          return (<div>共 <span>{total}</span> 条</div>)
        },
        onShowSizeChange: (current, pageSize) => {
          this.pageSize = pageSize
        }
      },
    }
  },
  computed: {
    columns () {
      return [
        {
          title: this.$t('user.number'),
          dataIndex: 'purchaseInfoId',
        },
        {
          title: this.$t('user.inquiry-title'),
          dataIndex: 'subject',
        },
        {
          title: this.$t('user.purchaser.company-name'),
          dataIndex: 'purchaser.companyName',
        },
        {
          title: this.$t('user.buy-number'),
          dataIndex: 'purchaseQuantity',
        },
        {
          title: this.$t('user.area'),
          dataIndex: 'purchaser.countryName',
        },
        {
          title: this.$t('user.inquiry-status'),
          dataIndex: 'statusText',
          scopedSlots: { customRender: 'action2' }
        },
        {
          title: this.$t('user.operation'),
          scopedSlots: { customRender: 'action' }
        },
      ]
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 改变每页数量时更新显示
    onChange1 (pagination) {
      this.pagination.defaultCurrent = pagination.current
      this.pagination.defaultPageSize = pagination.pageSize
      this.getList()
    },
    // 采购意向列表
    getList () {
       const params = {
         page: this.pagination.defaultCurrent,
         pagesize: this.pagination.defaultPageSize,
         status: ''
       }
       this.$common.fetchList('/user/purchase/lists', params).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.list = result.lists
          console.log(this.list)
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    },
    //处理采购意向
    checkstatus(id,status) {
      const params = {
         status: status
       }
      this.$common.handlePost('/user/purchase/edit/'+id, params).then(data => {
        if (data.code === 200) {
          this.$layer.alert(data.msg);
          this.reload()
        }
      }).catch(() => {
        this.$notification.error({
          message: '错误',
          description: '请求信息失败，请重试'
        })
      })
    }
  }
}
</script>